import React from 'react';
import BenParisot from '../assets/ben-headshot3.jpg';
import '../App.css';


export default function Footer() {
    return (
        <div id="splash-main">
          <div id="splash-sidebar">
          </div>
          <div id="splash-content">
            <div id="splash-content-left">
              <img src={BenParisot} alt="Ben Parisot headshot" />
            </div>
            <div id="splash-content-right">
              <h1>Ben can help you build that.</h1>
              <p>I am an engineering manager and design-first software developer working primarily in JavaScript, React, and Node. I'm passionate about clear product requirements, useful documentation, and intuitive design. Connect with me on LinkedIn and let's talk shop!</p>
              <a target="blank" href="https://www.linkedin.com/in/benparisot/"><button>Let's connect</button></a>
            </div>
          </div>
        </div>

    )
}
