import React from 'react';
import '../App.css';
import Splash from '../components/Splash';

function Home() {
  return (
    <div id="top" className="App">
      <Splash />
    </div>
  );
}

export default Home;
